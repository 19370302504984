import { Injectable } from '@angular/core';
import { iClient, iPortalType } from './interfaces/user';
import { EntityService } from './services/entity.service';
import { UserService } from './services/user/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../environments/environment';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';
import { switchMap, tap } from 'rxjs/operators';
import { StaticDataService } from '@coreservices/static-info.service';
import { LoginStartupService } from '@coreservices/login-startup.service';
import { CommunitySettingsService } from '@coreservices/community-settings.service';
import { UrlShortenedService } from '@coreservices/url-shortened.service';

@Injectable()
@UntilDestroy()
export class CoreInit {

  pagesWhoNeedLoginData = ['login', 'registration', 'forgotpass', 'community'];

  constructor(private _entityService: EntityService,
              private _userService: UserService,
              private _router: Router,
              private _ssr: ServerSideRenderingService,
              private _staticDataService: StaticDataService,
              private _loginStartupService: LoginStartupService,
              private _communitySettingsService: CommunitySettingsService,
              private _urlShortenedService: UrlShortenedService) {


    if(this._ssr.isBrowser) {
      // check if url encoded
      if(window.location.pathname.split('/')[1].slice(0, 2) === "SU")
        this._router.navigateByUrl(this._urlShortenedService.decodeUrl(window.location.href));
      // get and set community name settings
      this._communitySettingsService.getSettings();
      
      this._staticDataService.getStaticInfo();
      
      this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          localStorage.setItem('last_route', event.urlAfterRedirects);
          let urlWithoutQuery = event.urlAfterRedirects.split('?')[0].split('/')[1];
          if(this.pagesWhoNeedLoginData.includes(urlWithoutQuery) && !this._loginStartupService.loginStartupData) {
            this._loginStartupService.completeLoad = false;
            this._loginStartupService.getLoginStartupData();
          } else {
            this._loginStartupService.completeLoad = true;
          }
        }
      });
    }
  }


  initAccess() {
    if(this._ssr.isBrowser) {
      return new Promise<boolean>(resolve => {
        this._entityService.prolongate().pipe(
            tap((success_response: iClient) => {
              this._userService.setClient(success_response);
              if(!this.checkStaticPage() && (window.location.pathname === '/login' || window.location.pathname === '/') && (!window.location.search.includes('verification_code') && !window.location.search.includes('activation_code'))) {
                this.redirect(success_response.portal.domain);
              }
            }),
            switchMap(() => this._entityService.getWithNoAuth('entity/portal_type/get'))
          )
          .subscribe({
            next: (portal_types: iPortalType[]) => {
              this._userService.setPortalTypes(portal_types);
              if (this.checkStaticPage()) {
                resolve(true);
                return;
              }
              const currentPath = window.location.pathname;
              const queryParams = window.location.search;
              const role: string = this._userService.getRole();
              let routerLink: string = (role === 'admin' || role === 'content_creator') ? `/admin` : `/customer`;
              if(this._ssr.isBrowser) {
                const lastRoute: string = localStorage.getItem('last_route');
                if (currentPath.length > 1) {
                  let path = (role === 'admin' || role === 'content_creator') ? currentPath.split('admin/') : currentPath.split('customer/');
                  if (path.length > 1) routerLink += `/${path[1]}`;
                }
                if (lastRoute && (currentPath === lastRoute)) {
                  this._router.navigateByUrl(lastRoute+queryParams);
                }
              }
              resolve(true);
            },
            error: (err) => {
              console.warn("err ", err)
              if (this.checkStaticPage()) {
                resolve(true);
                return;
              }
              this._userService.deleteClient();
              resolve(false);
            }
          });
      });
    }
  }

  redirect(domain?: string): void {
    let role: string | null = this._userService.getRole();
    let token: string = this._userService.token;
    switch (role) {
      case 'admin':
      case 'portal_visitor':
      case 'content_creator':
        if (domain && this.centralAdmin(domain)) {
          this._userService.deleteClient();
          if (!environment.production) {
            domain = domain.slice(domain.lastIndexOf('/') + 1);
            window.location.href = 'http://' + domain + ':4200' + '/admin?hash=' + token; // debug
          } else {
            window.location.href = domain + '/admin?hash=' + token;
          }
        }
        this._router.navigate(['/admin']);
        break;
      case 'customer':
        this._router.navigateByUrl('/customer/dashboard'+window.location.search);
        break;
      default:
        this._router.navigate(['/login']);
        break;
    }
  }

  centralAdmin(domain: string): boolean {
    if (!environment.production) {
      return (window.location.origin !== (domain + '.loc:4200'));
    } else {
      return (window.location.origin !== domain);
    }
  }

  checkStaticPage(): boolean {
    let urlComponentList = window.location.pathname.split('/');
    const staticPageNames = [
      'landings',
      'forgotpass',
      'imprint',
      'privacy-policy',
      'terms-conditions',
      'registration',
      'partner',
      'support',
      'free-course',
      'signup',
      'confirmation',
      'user-onboarding',
      'trial-period-end'
    ];
    let isStatic = false;
    if (!urlComponentList.includes('admin')) {
      urlComponentList.forEach(urlPart => {
        if (staticPageNames.includes(urlPart)) {
          isStatic = true;
        }
      });
    }
    return isStatic;
  }
}
